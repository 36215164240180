import React from "react";
import { Layout } from "@startuponsale/gatsby-shared-ui";

const Privacidad = () => {
    return (
        <div>
          <Layout>
            <div className="w-full services bg-white">
                <div className="max-w-6xl mx-auto px-4 pt-8 pb-20 md:pb-64">
                    <h1>Política de Privacidad</h1>
                    <p>En cumplimiento de lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a 
                        la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos y en la 
                        Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, así como en su 
                        normativa de desarrollo, les facilitamos información ampliada en la presente Política de Privacidad.</p>
                    <p>Le informamos que todos los datos personales de la web así como los contenidos en cualquier tipo de comunicación con la compañía 
                        formarán parte de un fichero propiedad de Kaibigan Investments S.L. (en adelante, "SoS") con dirección Camí de Ximelis 43, 08191, 
                        Rubí, Barcelona, con identificación fiscal B-57979551, con el objetivo de tener la información disponible y contactar a los usuarios.</p>

                    <h2>Con qué finalidad tratamos sus datos personales de la web</h2>
                    <p>En SoS tratamos la información que nos facilitan las personas interesedas con el fin de prestar los servicios ofrecidos en nuestra web, así como
                        el envío de comunicaciones comerciales y newsletters sobre novedades y eventos a nuestros clientes y contactos.
                    </p>
                    <p>Además, le informamos que SoS puede enviarle comunicaciones a través de cualquier canal, incluyendo redes sociales, sobre oportunidades de compra/venta de los proyectos o empresas listados en el directorio.</p>

                    <h2>A qué destinatarios se comunicarán sus datos</h2>
                    <p>Los datos se comparten exclusivamente con inversores participantes en nuestra comunidad, para el análisis correspondiente de la oportunidad de compra/venta y en su caso tener contacto directo con el usuario.
                        No se comunicarán datos a terceros ajenos a la actividad de la entidad, salvo obligación legal.
                    </p>

                    <h2>Por cuánto tiempo conservaremos sus datos</h2>
                    <p>Los datos personales proporcionados se conservarán por el tiempo imprescindible para la prestación del servicio solicitado, mientras el interesado no solicite su supresión y durante los plazos legalmente establecidos.</p>

                    <h2>Cuál es la legitimación para el tratamiento de sus datos</h2>
                    <p>La base legal para el tratamiento de sus datos será, según el caso, el consentimiento del interesado,  la ejecución de un contrato o el interés legítimo.
                        El tratamiento de datos para el envío de comunicaciones comerciales o newsletters a nuestros asociados, participantes, clientes, contactos comerciales y usuarios que se hayan registrado en nuestra web se ampara en el interés legítimo establecido en el Reglamento Europeo de Protección de Datos y en la Ley de Servicios para la Sociedad de la Información y de Comercio Electrónico. Si prefiere no recibir estos mensajes por correo electrónico le ofreceremos a través de los mismos la posibilidad de ejercer su derecho de oposición a dicho tratamiento.</p>

                    <h2>Más información sobre el tratamiento de sus datos</h2>
                    <p>Cuando precisemos obtener información por su parte, siempre le solicitaremos que nos la proporcione voluntariamente prestando su consentimiento de forma expresa a través de los medios habilitados para ello.
                        El tratamiento de los datos recabados a través de los formularios de recogida de datos del sitio web u otras vías, quedará incorporado al Registro de Actividades de Tratamiento del cual es responsable SoS.
                        
                        SoS trata los datos de forma confidencial y adopta las medidas técnicas y organizativas apropiadas para garantizar el nivel de seguridad adecuado al tratamiento, en cumplimiento de lo requerido por el Reglamento (UE) 2016/679 del 
                        Parlamento Europeo y del Consejo de 27 de abril de 2016 y demás normativa aplicable en materia de Protección de Datos.
                        
                        No obstante, SoS no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan 
                        causar en los sistemas informáticos, documentos electrónicos o ficheros del usuario.
                        
                        Si opta por abandonar nuestro sitio web a través de enlaces a sitios web no pertenecientes a nuestra entidad, SoS no se hará responsable de las políticas de privacidad de dichos sitios web ni de las cookies que éstos puedan almacenar en el ordenador del usuario.</p>
                </div>
            </div>
        </Layout>
        </div>
    );
}


export default Privacidad;